import $ from "jquery";

export default function search() {
  function init() {
    // open search layer
    $(".header").on("click", ".header__search", function () {
      $("body").addClass("block-site");
      $(".search").addClass("search--open");
    });

    // close search layer
    $(".search").on("click", ".search__close", function () {
      $("body").removeClass("block-site");
      $(".search").removeClass("search--open");
      document.getElementById("search-btn").focus();
    });
  }

  $(document).ready(function () {
    if ($(".search").length > 0) {
      init();
    }
  });
}
