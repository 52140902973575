import $ from 'jquery';
import 'slick-carousel';

export default function slider() {

  function sliderHero() {
    const $heroSlider = $('.slider-hero');
    const $heroNavSlider = $('.slider-hero-nav');

    if ($heroSlider.length) {
      var currentSlide;
      var slidesCount;
      var sliderCounter = document.createElement('div');
      sliderCounter.classList.add('slider-hero__counter');
      
      var updateSliderCounter = function(slick, currentIndex) {
        currentSlide = slick.slickCurrentSlide() + 1;
        slidesCount = slick.slideCount;
        $(sliderCounter).text(currentSlide + '/' + slidesCount)
      };

      $heroSlider.on('init', function(event, slick) {
        $heroSlider.append(sliderCounter);
        updateSliderCounter(slick);
      });

      $heroSlider.on('afterChange', function(event, slick, currentSlide) {
        updateSliderCounter(slick, currentSlide);
      });

      $heroSlider.slick({
        arrows: false,
        dots: false,
        // dotsClass: "slick-dots container",
        asNavFor: '.slider-hero-nav',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        fade: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: true,
              // appendArrows: $('.slider-controls-hero'),
              dots: false,
              infinite: false,
              fade: false,
              autoplay: false
            }
          }
        ]
      });
    }

    if($heroNavSlider.length > 0) {
      $heroNavSlider.slick({
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        focusOnSelect: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        asNavFor: '.slider-hero'
      });
    }
  }

  function sliderCircolari() {
    $('.slider-circolari').slick({
      arrows: true,
      appendArrows: $('.slider-controls-circolari'),
      dots: true,
      // appendDots: $('.slider-controls-circolari .dots'),
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            appendArrows: $('.slider-circolari'),
            // appendDots: $('.slider-circolari'),
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }
  
  function sliderEvents() {
    $('.slider-events').slick({
      arrows: true,
      appendArrows: $('.slider-controls-events'),
      dots: true,
      vertical: true,
      verticalSwiping: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            vertical: false,
            verticalSwiping: false,
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderNews() {
    $('.slider-news').slick({
      arrows: true,
      appendArrows: $('.slider-controls-news'),
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderAttivita() {
    $('.slider-attivita').slick({
      arrows: true,
      appendArrows: $('.slider-controls-attivita'),
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderPubblicazioni() {
    $('.slider-pubblicazioni').slick({
      arrows: true,
      appendArrows: $('.slider-controls-pubblicazioni'),
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderImpegno() {
    const $impegnoSlider = $('.slider-impegno');
    const $impegnoNavSlider = $('.slider-impegno-nav');

    if ($impegnoSlider.length) {
      $impegnoSlider.slick({
        arrows: false,
        appendArrows: $('.slider-controls-impegno'),
        dots: false,
        // dotsClass: "slick-dots container",
        asNavFor: '.slider-impegno-nav',
        infinite: false,
        autoplay: false,
        swipe: false,
        autoplaySpeed: 6000,
        fade: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: true,
              dots: true,
              infinite: false,
              fade: false,
              autoplay: false,
              swipe: true
            }
          }
        ]
      });

      if($impegnoNavSlider.length > 0) {
        $impegnoNavSlider.slick({
          arrows: false,
          dots: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 6000,
          focusOnSelect: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          asNavFor: '.slider-impegno'
        });
      }
    }
  }

  function sliderNetwork() {
    const $networkSlider = $('.slider-network');
    const $networkNavSlider = $('.slider-network-nav');

    $networkSlider.slick({
      arrows: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.slider-network-nav',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: true,
          }
        }
      ]
    });

    if($networkNavSlider.length > 0) {
      $networkNavSlider.slick({
        arrows: false,
        dots: false,
        focusOnSelect: true,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 5,
        slidesToScroll: 5,
        asNavFor: '.slider-network',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '15%',
            }
          }
        ]
      });
    }
  }

  function sliderContatti() {
    $('.slider-contatti').slick({
      arrows: true,
      appendArrows: $('.slider-controls-contatti'),
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 991,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '15%',
          }
        }
      ]
    });
  }

  function sliderGallery() {
    $(".slider-gallery").each(function () {
      let slider = $(this);

      slider.slick({
        arrows: true,
        appendArrows: slider.parents('.photogallery').find('.slider-controls-gallery'),
        dots: true,
        infinite: false,
        autoplay: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              slidesToShow: 1,
              centerPadding: '15%',
            }
          }
        ]
      });
    });
  }
  
  $( document ).ready(function() {
    if($('.slider-hero').length > 0) {
      sliderHero();
    }

    if($('.slider-circolari').length > 0) {
      sliderCircolari();
    }

    if($('.slider-events').length > 0) {
      sliderEvents();
    }

    if($('.slider-news').length > 0) {
      sliderNews();
    }

    if($('.slider-attivita').length > 0) {
      sliderAttivita();
    }

    if($('.slider-pubblicazioni').length > 0) {
      sliderPubblicazioni();
    }

    if($('.slider-impegno').length > 0) {
      sliderImpegno();
    }

    if($('.slider-network').length > 0) {
      sliderNetwork();
    }

    if($('.slider-contatti').length > 0) {
      sliderContatti();
    }

    if($('.slider-gallery').length > 0) {
      sliderGallery();
    }
  });
}