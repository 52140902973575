import $ from 'jquery';

export default function utils() {
  
  $('html').removeClass('no-js');

  /*$("a[href^=http], a.external").on("click", function () {
    window.open(this.href);
    return false;
  });*/

  //gestione menu responsive
  let mediaQueryDesktop = window.matchMedia("(min-width: 992px)");
  function widthChangeCallback(mediaQueryDesktop) {
    $(".navigation .navigation__item").removeClass("navigation__item--open");
    $("body").removeClass("block-site");
    $(".block-site-content").removeClass("active");

    if (mediaQueryDesktop.matches) {
      $(".hamburger").removeClass("open");
      $(".navigation").removeClass("navigation--open");
    } else {
      $(".megamenu").removeClass("megamenu--open");
      $(".block-site-content").removeClass("active");
    }
  }
  mediaQueryDesktop.addEventListener("change", widthChangeCallback);
  widthChangeCallback(mediaQueryDesktop);
  
}