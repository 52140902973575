import $ from "jquery";

export default function megamenu() {
  function init() {

    $("button.navigation__link").on("click", function () {
      if ($(this).parents(".navigation__item").hasClass("navigation__item--open")) {
        $(this).attr("aria-expanded", false);
        $(this).parents(".navigation__item").removeClass("navigation__item--open");
        $(this).parents(".navigation__item").children(".megamenu").removeClass("megamenu--open");
        $(".block-site-content").removeClass("active");
      } else {
        $(this).attr("aria-expanded", true);
        $(".block-site-content").addClass("active");
        $(".navigation__item").removeClass("navigation__item--open");
        $(this).parents(".navigation__item").addClass("navigation__item--open");
        $(this).parents(".navigation__item").children(".megamenu").addClass("megamenu--open");
      }
    });

    // back megamenu smartphone
    $(".megamenu__back").on("click", function () {
      $(".navigation__item").removeClass("navigation__item--open");
      $(".megamenu").removeClass("megamenu--open");
    });

  }

  $(document).ready(function () {
    if ($("button.navigation__link").length > 0) {
      init();
    }
  });
}
