import $ from 'jquery';
import 'select2';

export default function select() {
  //recupero lista corsi e utenti
  // let data = JSON.parse(document.getElementById('dataRecommend').innerHTML);
  // let listCourses = data.courses;
  // let listUsers = data.users;

  let langSite = $('html')[0].lang;
  let placeholderSelect = langSite === "it" ? "Seleziona" : "Select";

  function init() {
    if ($(".select-area-tematica").length > 0) {
      $('.select-area-tematica').select2({
        placeholder:  placeholderSelect,
        allowClear: true
      });
    }

    if ($(".select-area-materia").length > 0) {
      $('.select-area-materia').select2({
        placeholder:  placeholderSelect,
        allowClear: true
      });

      $(".select-area-tematica").on('select2:select', function(e) {
        $('.select-area-materia').prop('disabled', false);
      });
    }

    if ($(".select-contenttype").length > 0) {
      $('.select-contenttype').select2({
        placeholder:  placeholderSelect,
        allowClear: true
      });
    }

    // $('.select.select-course').select2({
    //   placeholder:  "search title",
    //   val: courses.map(course => course.val),
    //   data: courses.map(course => course.data)
    // }).data('select2').$dropdown.addClass("ferragamo-select");
  }
  
  $( document ).ready(function() {
    if($('.select').length > 0) {
      init();
    }
  });
}