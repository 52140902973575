import $ from "jquery";

export default function accordion() {
  function init() {
    $(".box-accordion .box-accordion__head").on("click", function () {
      if (!$(this).parents(".box-accordion").hasClass("open")) {
        $(this).attr("aria-expanded", true);
        $(this).parents(".box-accordion").addClass("open");
      } else {
        $(this).attr("aria-expanded", false);
        $(this).parents(".box-accordion").removeClass("open");
      }
    });
  }

  $(document).ready(function () {
    if ($(".box-accordion").length > 0) {
      init();
    }
  });
}
