import $ from 'jquery';

export default function liferay() {

  function viewAll() {
    $('.js-viewall').on('click', function (e){
	  e.preventDefault();
      $(this).addClass('d-none').next('.js-viewless').removeClass('d-none').closest(".js-view-container").find("li").removeClass("d-none");
    });
    $('.js-viewless').on('click', function (e){
	  e.preventDefault();
      var maxElem = $(this).closest(".js-view-container").data("maxelem") || 5;
      $(this).addClass('d-none').prev('.js-viewall').removeClass('d-none').closest(".js-view-container").find(maxElem >= 0 ? "li:gt("+maxElem+")" : "li").addClass("d-none");
    });
  }

  function favourite() {
    // clickOut
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".bookmark").length) {
        console.log("test");
        $('.bookmark__info').addClass('d-none');
      }
    });

    $(document).on('click', '.bookmark__btn:not(.bookmark__btn--active)', function (){
      if($(this).parents('.bookmark').hasClass('show-tooltip')) {
        console.log("show-tooltip");
        $(this).parents('.bookmark').children('.bookmark__info').toggleClass('d-none');
      } else {
        $(this).addClass('bookmark__btn--active');
      }
    });

    $(document).on('click', '.bookmark__btn.bookmark__btn--active', function (){
      // $(this).parents('.col-12.col-md-6.col-lg-4').addClass('d-none');
      if($(this).parents('.bookmark').hasClass('show-tooltip')) {
        console.log("show-tooltip");
        $(this).parents('.bookmark').children('.bookmark__info').toggleClass('d-none');
      } else {
        $(this).removeClass('bookmark__btn--active');
      }
    });
  }
  
	// Questa funzione permette di aggiungere o rimuovere un bookmark.
    // Evento scatenante: click su elemento con classe bookmark__btn
    // Funzioni chiamate:
    // > _removeContentFavourite(favouriteId, classPK)
    // > _addContentFavourite(className, classPK, contentType, userId, groupId)
    function initAddOrRemoveContentFavourite(){
        $(".bookmark__btn.bookmark_enabled_js").each(function(){
            $(this).on("click", function(e){
                e.preventDefault();
				e.stopPropagation();
                if($(this).hasClass("bookmark__btn--active")){
                    let favouriteId = $(this).attr('data-bookmarkId');
                    let classPK = $(this).attr('data-classPK');
                    _removeContentFavourite(favouriteId, classPK);
                }else{
                    let className = $(this).attr('data-className');
                    let classPK = $(this).attr('data-classPK');
                    let contentType = $(this).attr('data-structureId');
                    let userId = $(this).attr('data-userId');
                    let companyId = $(this).attr('data-companyId');
                    _addContentFavourite(className, classPK, contentType, userId, companyId);
                }
            });
        });
    }
	
	// Questa funzione permette di settare la privacy dell'utente
	function _setPrivacyUser(userId){
		if(userId>0){
			console.log("userID: " + userId);
			$('#btn-privacy-user').on('click', function (e){
				console.log("clicik btn _setPrivacyUser...");
				$.ajax({
					type: "PUT",
					url: "/o/confindustria/user/set-privacy?userId="+userId,
					cache: false,
					async: false,
					dataType: "json",
					success: function(data) {
						console.log("Success set privacy user, return page: " + data.url);
						document.location.href= data.url;
					},
					error: function(xhr, ajaxOptions, thrownError) {
						if(console){
							console.log(xhr.status);
							console.log(thrownError);
						}
					}
				});
			});
		}
    }
	
	
	function _addContentFavourite(className, classPK, contentType, userId, companyId){
        $.ajax({
            type: "GET",
            url: "/o/confindustria/manager/favourite/add-favourite?className="+className+"&classPK="+classPK+"&userId="+userId+"&companyId="+companyId+"&contentType="+contentType,
            cache: false,
            async: false,
            dataType: "json",
            success: function(data) {
                let bookmarkButtonClassPK = $('button[data-classPK=' + classPK + ']');
                bookmarkButtonClassPK.addClass('bookmark__btn--active');
                bookmarkButtonClassPK.attr("data-bookmarkId", data.favouriteId);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                if(console){
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            }
        });
    }
	
	function _removeContentFavourite(favouriteId, classPK){
        $.ajax({
            type: "GET",
            url: "/o/confindustria/manager/favourite/remove-favourite?favouriteId="+favouriteId,
            cache: false,
            async: false,
            dataType: "json",
            success: function(){
				let bookmarkButtonClassPK = $('button[data-classPK=' + classPK + ']');
				bookmarkButtonClassPK.removeClass('bookmark__btn--active');
            },
            error: function(xhr, thrownError){
                if(console){
                    console.log(xhr);
                    console.log(thrownError);
                }
            }
        });
    }

  $( document ).ready(function(){ 

    if ($('.js-viewall').length > 0) {
      viewAll();
    }

    if ($('.bookmark .bookmark__btn').length > 0) {
      favourite();
    }
	
	if ($('.bookmark__btn.bookmark_enabled_js').length > 0) {
      initAddOrRemoveContentFavourite();
    }
	
	if ($('#btn-privacy-user').length > 0) {
       let userId = $('#btn-privacy-user').attr("data-user");		
      _setPrivacyUser(userId);
    }
    
  });
}