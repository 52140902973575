import $ from "jquery";

export default function hamburger() {
  function init() {
    $(".hamburger").on("click", function () {
      if (!$(this).hasClass("open")) {
        $("body").addClass("block-site");
        $(this).addClass("open");
        $(".header").addClass("header--open");
        $(".navigation").addClass("navigation--open");
      } else {
        $("body").removeClass("block-site");
        $(this).removeClass("open");
        $(".header").removeClass("header--open");
        $(".navigation").removeClass("navigation--open");
        $(".block-site-content").removeClass("active");
      }
    });
  }

  $(document).ready(function () {
    if ($(".hamburger").length > 0) {
      init();
    }
  });
}
