import './polyfill/currentScript'
import 'core-js/stable' 
import 'regenerator-runtime/runtime'

import utils from './utils'
import hamburger from './hamburger'
import megamenu from './megamenu'
import slider from './slider'
import prototipo from './prototipo'
import liferay from './liferay'
import datepicker from './datepicker'
import select from './select'
import search from './search'
import lightbox from './lightbox'
import selectArea from './select-area'
import filterResults from './searchable'
import accordion from './accordion'

const bundle_url = new URL(document.currentScript.src);
__webpack_public_path__ = bundle_url.pathname.substring(0, bundle_url.pathname.lastIndexOf("/")+1);

hamburger();
megamenu();
slider();
utils();
select();
search();
lightbox();
selectArea();
filterResults();
accordion();

if (!process.env.LIFERAY_ENV) {
  prototipo();
	datepicker();
}

if (process.env.LIFERAY_ENV) {
  liferay();
}